! function ($) {
    "use strict";

    var SubmitOnChange = function () {};

    SubmitOnChange.prototype.init = function () {

        $('.js-submit-on-change').change(function() {
            var $selector = $(this);
            var $form = $selector.closest('form');
            $form.submit();
        });
    };

    $.SubmitOnChange = new SubmitOnChange();

}(window.jQuery),


    function ($) {
        "use strict";
        $.SubmitOnChange.init()
    }(window.jQuery);