!function ($) {
    "use strict";

    var AccountSelector = function () {
    };

    AccountSelector.prototype.init = function () {

        $('.js-account-selector').change(function () {
            var $selector = $(this);
            var $form = $selector.closest('form');

            $.post('/select-account', $form.serialize())
                .done(function () {
                    /* could trigger an event here and reload data dynamically instead of doing a redirect */
                    window.location.href = window.location.href;
                })
            ;
        });

        window.setTimeout(function () {
            $('.profile-dropdown').removeAttr('style');
        }, 500);
    };

    $.AccountSelector = new AccountSelector();

}(window.jQuery),


    function ($) {
        "use strict";
        $.AccountSelector.init()
    }(window.jQuery);