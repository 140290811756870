/**
 * Module/App: Admin Section
 */

!function ($) {
    "use strict";


    var init = function () {
        dataGraph('.monthlycpa-graph', {
            data: function () {
                return JSON.parse($('#accumulative-data').html());
            },
            title: 'Cost Per Transactions',
            lines: [{
                name: 'cpl',
                yAxis: 'cpl',
                colour: '#dd1448'
            }],
            XAxis: 'time',
            dateFields: ['time'],
            accumulate: []
        });

    };

    // The init will run in the document ready
    $(init);

}(window.jQuery);