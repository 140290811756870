!function ($) {

    var Report = function () {
    };
    var selectedClass1 = 'bg-success';
    var selectClass2 = 'border-success';

    Report.prototype.init = function () {

        var $form = $('form.js-dashboard');

        function submitForm() {
            $('.loader-overlay').show();
            $form.submit();
        }

        function setDeviceSelect($container, defaultValue) {

            if (!$container.length) {
                return;
            }

            var $field = $container.find('.button-value');

            $container.find('button').click(function (e) {

                var value = $(this).data('btn-value');

                if (parseInt($field.attr('value')) !== parseInt(value)) {

                    var otherButton = $(this).find('button');
                    otherButton.removeClass(selectedClass1);
                    otherButton.removeClass(selectClass2);

                    $(this).addClass(selectedClass1);
                    $(this).addClass(selectClass2);

                    $field.attr("value", value);
                } else {
                    $(this).removeClass(selectedClass1);
                    $(this).removeClass(selectClass2);
                    $field.attr('value', defaultValue);
                }

                submitForm();
            });
        }

        setDeviceSelect($('#grouping-selection'), 'DAILY');
        setDeviceSelect($('#platform-selection'), '');
        setDeviceSelect($('#yoy-selection'), '');
        setDeviceSelect($('.device-selection'), '');
    };

    $.Report = new Report();

}(window.jQuery),

    function ($) {
        "use strict";
        $.Report.init()
    }(window.jQuery);