var briteChartApp = window.briteChartApp || {};

(function ($, context) {
    "use strict";

    context.createBarChart = function (elementSelector, data) {

        var chartTooltip = new britecharts.miniTooltip();
        var _barChart = new britecharts.groupedBar();
        var barContainer = d3.select(elementSelector),
            containerWidth = barContainer.node()
                ? barContainer.node().getBoundingClientRect().width
                : false,
            containerHeight = barContainer.node()
                ? barContainer.node().getBoundingClientRect().height
                : false,
            tooltipContainer = void 0;

        var margin = {
            top: 10,
            left: 80,
            bottom: 80,
            right: 10
        };

        var chartSize = [
            containerWidth - margin.left - margin.right,
            containerHeight - margin.top - margin.bottom
        ];

        _barChart
            .isAnimated(true)
            .width(containerWidth)
            .height(containerHeight)
            .margin(margin)
            .colorSchema(["#39afd1", "#fa5c7c"])
            .groupLabel('group')
            .nameLabel('name')
            .valueLabel('value')
            .on("customMouseOver", chartTooltip.show)
            .on("customMouseMove", chartTooltip.update)
            .on("customMouseMove", function(dataPoint, colorMapping, x, y) {

                chartTooltip.update(dataPoint, [-60,-60], chartSize);
            })
            .on("customMouseOut", chartTooltip.hide)
        ;

        chartTooltip.nameLabel("group");
        chartTooltip.numberFormat([",.1f"]);


        barContainer.datum(data).call(_barChart);
        tooltipContainer = d3.select(elementSelector + " .metadata-group");
        tooltipContainer.datum([]).call(chartTooltip);

    };

    function init() {

        function timeSeriesRowsToChartData(rows)
        {
            var keys = [],
                data = [],
                time = null,
                $selected = $('div.time-series-selection input[type="checkbox"]:checked');

            $selected.each(function(i, el) {

                keys.push($(el).val());
            });

            for(var i = 0; i < rows.length; i++)
            {
                for(var j = 0; j < keys.length; j++) {

                    data.push({
                        'name' : rows[i].timeLabel,
                        'group' : keys[j],
                        'value' : rows[i][keys[j]]
                    });

                }
            }

            return data;
        }

        function drawCharts() {

            d3.selectAll('.time-series-container svg').remove();

            if ($(".time-series-container").length > 0) {

                var rows = $(".time-series-container").data('rows');
                var data = timeSeriesRowsToChartData(rows);


                briteChartApp.createBarChart(".time-series-container", data);
            }
        }

        // responsive charts
        $(window).on('resize', function (e) {
            e.preventDefault();
            setTimeout(drawCharts, 200);
        });


        $('div.time-series-selection input[type="checkbox"]').change(function(e) {

            // if unchecking, don't do anything
            if($(this).is(':checked')) {

                // ensure any not in the clicked group are unchecked...
                var group = $(this).data('group');

                $('div.time-series-selection input[type="checkbox"]').each(function(i, el) {

                    var $cb = $(el);

                    if($cb.data('group') != group) {

                        $cb.prop('checked', null);

                    }
                });
            }

            //render charts
            drawCharts();

            return true;

        });


        // $('div.time-series-selection input[type="checkbox"]').change(drawCharts);

        //render charts
        drawCharts();
    }

    // The init will run in the document ready
    $(init);
})(jQuery, briteChartApp);