/**
 * Module/App: Admin Section
 */

! function ($) {
    "use strict";

    var UserAdmin = function () {};

    UserAdmin.prototype.init = function () {

        var $collectionHolder;

        function addUserRow($collectionHolder, $newLinkLi) {
            // Get the data-prototype explained earlier
            var prototype = $collectionHolder.data('prototype');

            // get the new index
            var index = $collectionHolder.data('index');

            var newRow = prototype;
            newRow = newRow.replace(/__name__/g, index);

            // increase the index with one for the next item
            $collectionHolder.data('index', index + 1);

            $collectionHolder.append(newRow);
        }

        // setup an "add a tag" link
        var $addUserButton = $('button.admin-user-new');

        // Get the ul that holds the collection of tags
        $collectionHolder = $('tbody.admin-users');

        // count the current form inputs we have (e.g. 2), use that as the new
        // index when inserting a new item (e.g. 2)
        $collectionHolder.data('index', $collectionHolder.find('tr.admin-user-row').length);

        $addUserButton.on('click', function(e) {
            // add a new tag form (see next code block)
            addUserRow($collectionHolder);

            // focus
            $collectionHolder
                .find('tr.admin-user-row')
                .last()
                .find('input')
                .first()
                .focus();
        });

        // show/hide regions toggle
        $('button.admin-user-regions-toggle')
            .click(function(e) {

                var $userRow = $(this).closest('tr');
                var $icon  = $(this).find('i');
                var $userRegionsRow = $userRow.next('tr.admin-user-regions-row');

                $userRegionsRow.toggleClass('hide');
                $icon.toggleClass('mdi-chevron-double-down mdi-chevron-double-up')
            });

    };

    $.UserAdmin = new UserAdmin();

}(window.jQuery),

    function ($) {
        "use strict";
        $.UserAdmin.init()
    }(window.jQuery);