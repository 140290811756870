! function ($) {
    "use strict";

    var RegionalMap = function () {

        this.ratio      = 1929.2 / 3420.3;
        this.$container = null;
        this.$mapSvg    = null;
        this.imgWidth   = null;
        this.imgHeight  = null;

        this.ragMap     = {
            "R" : "#e60061",
            "A" : "#ffc950",
            "G" : "#85de67"
        };

        this.ragHighlightMap = {
            "R" : "#FF3394",
            "A" : "#F1E1A3",
            "G" : "#9FF881"
        };

        // map region classes to regionIds

        this.regionClassMap = {
            "cls-5" : 16,   // maidstone
            "cls-11" : 27, // scotland
            "cls-10" : 17, // north-west
            "cls-7": 18,    // london-east-anglia
            "cls-9" : 24, // midlands
            "cls-8" : 22, // east-england
            "cls-1" : 20,   // sunbury-north
            "cls-2" : 19, // south-west
            "cls-4" : 23, //sunbury-south
            "cls-3" : 26,   // not-transactional
        };
    };

    RegionalMap.prototype.init = function () {

        var rm = this;

        // multi modal fix
        $(document).on('show.bs.modal', '.modal', function () {
            var zIndex = 1040 + (10 * $('.modal:visible').length);
            $(this).css('z-index', zIndex);
            setTimeout(function() {
                $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
            }, 0);
        });

        this.$container = $('#region-map-container');

        function onMapLoad() {

            rm.$mapSvg    = $('#region-map-container svg');

            rm.resizeMapSvg();
            rm.refreshRegionColors();
            rm.hookupClickEvents();
            rm.hookupHoverEvents();

            // show map once we have done the initial setup
            rm.$container.css('display', 'block');


            $( window ).resize(function() {
                rm.resizeMapSvg();
            });
        }

        // load the map SVG
        this.$container.load('../assets/map/map.svg', onMapLoad);
    };

    RegionalMap.prototype.hookupHoverEvents = function()
    {
        function refreshDetailsFromModal($regionModal) {

            $('#region-details').find('span.js-region-name').text($regionModal.data('region-name'));

            $('#region-details').find('td.js-region-cpl').text($regionModal.data('region-cpl'));
            $('#region-details').find('td.js-region-spend').text($regionModal.data('region-spend'));
            $('#region-details').find('td.js-region-leads').text($regionModal.data('region-leads'));

            $('#region-details').find('td.js-region-cpl-target').text($regionModal.data('region-cpl-target'));
            $('#region-details').find('td.js-region-spend-target').text($regionModal.data('region-spend-target'));
            $('#region-details').find('td.js-region-leads-target').text($regionModal.data('region-leads-target'));
        }

        var above = function(regionId) {

            return function(e) { refreshDetailsFromModal($('#map-region-modal-' + regionId)); };
        };

        var leave = function() { refreshDetailsFromModal($('#map-market-modal')); };

        // hookup click events
        for(var rClass in this.regionClassMap) {

            var regionId = this.regionClassMap[rClass];

            if(regionId === null) {

                continue;
            }

            this.$mapSvg.find('.' + rClass).mouseover(above(regionId));
            this.$mapSvg.find('.' + rClass).mouseleave(leave);
        }

    };

    RegionalMap.prototype.hookupClickEvents = function()
    {
        var clickFunc = function(regionId) {

            return function(e) {
                $.RegionalMap.regionClick(regionId);
            };
        };

        // hookup click events
        for(var rClass in this.regionClassMap) {

            var regionId = this.regionClassMap[rClass];

            if(regionId === null) {

                continue;
            }

            this.$mapSvg.find('.' + rClass).click(clickFunc(regionId));
        }

        $('div.js-map-region-modal .js-regional-adjustment-cancel').click(function() {
            var $cancel = $(this);

            var $modal = $cancel.closest('.modal');
            $modal.modal('hide');
        });

        $('div#map-key a').click(function(e) {

            e.preventDefault();

            var regionId = $(this).data('region-id');

            $.RegionalMap.regionClick(regionId);
        });
    }

    RegionalMap.prototype.regionClick = function(regionId)
    {
        console.log('region ' + regionId);

        this.openModal(regionId);
    }

    RegionalMap.prototype.lookupClass = function(regionId)
    {
        for(var k in this.regionClassMap) {

            if(this.regionClassMap[k] === regionId) {

                return k;
            }
        }

        return null;
    }

    RegionalMap.prototype.refreshRegionColors = function()
    {
        console.log('refresh colours');
        var rm = this;

        var styles = '';
        var $style = this.$mapSvg.find('style').first();

        // set rag colors on regions
        $('div.js-map-region-modal').each(function(i,e) {

            var $regionModal = $(e);

            var regionId    = $regionModal.data('region-id');
            var ragStatus   = $regionModal.data('rag-status');
            var regionColor     = rm.ragMap[ragStatus];
            var regionHighlight = rm.ragHighlightMap[ragStatus];
            var regionClass     = rm.lookupClass(regionId);

            styles += '.' + regionClass + '{fill:' + regionColor +
                '; stroke: white; stroke-width: 2.5;} \n';

            styles += ' .' + regionClass + ':hover {cursor: pointer; fill:' +
                regionHighlight + ';} \n';

        });

        $style.append(styles);
    }

    RegionalMap.prototype.resizeMapSvg = function () {

        // how much vertical space is available for the map?
        // there are 190px worth of padding, margins and top bar
        console.log('window inner height ' + window.innerHeight);

        this.imgHeight = window.innerHeight - 170;
        this.$mapSvg.height(this.imgHeight);
    };

    RegionalMap.prototype.openModal = function(regionId) {

        var $modal = $('div#map-region-modal-' + regionId);

        // un-collapse
        $modal.find('.collapse').removeClass('collapse');

        $modal.modal('show');
    };

    $.RegionalMap = new RegionalMap();

}(window.jQuery),


    function ($) {
        "use strict";
        $.RegionalMap.init()
    }(window.jQuery);
