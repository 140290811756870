/**
 * Module/App: Admin Section
 */

!function ($) {
    "use strict";


    var init = function () {
        pieChart('#monthly-campaign-split', {
            data: function () {
                var element = document.getElementById('pie-data');
                return JSON.parse(element.innerHTML);
            },
            title: 'Campaign Type Spend Spilt',
            radius: 200,
            colours: [
                '#b013ff',
                '#dd1448',
                '#feb522',
                '#00a7ff',
                '#40f2fd',
                '#fde93e',
                '#e488b3',
                '#a2d3aa'
            ]
        });

    };

    // The init will run in the document ready
    $(init);

}(window.jQuery);