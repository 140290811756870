! function ($) {
    "use strict";

    var RegionalBudgetAdjustment = function () {};

    RegionalBudgetAdjustment.prototype.init = function () {

        function refreshRag($panel) {

            var confidenceAlpha        = $panel.data('confidence-alpha');
            var confidenceBeta         = $panel.data('confidence-beta');
            var confidenceThresholdMin = $panel.data('probability-threshold-min');
            var confidenceThresholdMax = $panel.data('probability-threshold-max');
            var daysRemaining          = $panel.find('input.js-regional-adjustment-budget').data('days-remaining');
            var origBudget             = $panel.find('input.js-regional-adjustment-budget').data('current-achieved');
            var budget                 = $panel.find('input.js-regional-adjustment-budget').val();
            var dailySpend             = $panel.find('input.js-regional-adjustment-budget').data('daily-achieved');
            var $bar                   = $panel.find('div.progress-bar');

            // update visual bar colour based on confidence
            // confidence=alpha*exp(-beta*((s-s0)/s0)^2)
            //
            //     where alpha=confidence_alpha, beta=confidence_beta, as just put in the database
            //
            //     and s=additional spend from the slider, and s0=daily_spend*days_left
            var additionalSpend = (budget - origBudget);
            var spendLeft       = daysRemaining * dailySpend;
            var confidence      = confidenceAlpha * Math.exp(-confidenceBeta * Math.pow(((additionalSpend - spendLeft) / spendLeft), 2));

            // convert to pct
            confidence *= 100;

            $bar.removeClass('ji-red-bg ji-amber-bg ji-green-bg');

            if(confidence > confidenceThresholdMax) { // green

                $bar.addClass('ji-green-bg');
            }
            else if(confidence > confidenceThresholdMin) { // amber

                $bar.addClass('ji-amber-bg');
            }
            else { // red

                $bar.addClass('ji-red-bg');
            }
        }

        $('.js-regional-adjustment').change(function() {
            var $elem = $(this);
            var $slider = $elem.closest('.form-group').find('.js-regional-adjustment-slider');
            var $visualBar = $elem.closest('.form-group').find('.progress-bar');
            var $partnerControl = $('#'+$elem.data('update-target'));

            var partnerExponent = $partnerControl.data('exponent');
            var partnerAchieved = $partnerControl.data('current-achieved');
            var partnerDailyAchieved = $partnerControl.data('daily-achieved');

            var newTarget = parseInt($elem.val());
            var oldTarget = $elem.data('bts-min');
            var maxTarget = $elem.data('bts-max');

            var currentAchieved = $elem.data('current-achieved');
            var dailyAchieved = $elem.data('daily-achieved');

            var daysRemaining = $elem.data('days-remaining');

            var required = newTarget - currentAchieved;
            var dailyRequired = required / daysRemaining;

            var ratio = dailyRequired / dailyAchieved;

            var partnerExpected = Math.round(partnerAchieved + (daysRemaining * partnerDailyAchieved * Math.pow(ratio, partnerExponent)));

            // update partner control with its new value
            $partnerControl.trigger('update-value', partnerExpected);

            // update slider
            var sliderPercent = Math.round((newTarget - oldTarget) / (maxTarget - oldTarget) * 100);

            $slider.val(sliderPercent);

            // update visual bar
            $visualBar.css('width', sliderPercent + '%');
            $visualBar.attr('aria-valuenow', sliderPercent);

        });

        $('.js-regional-adjustment').on('update-value', function (event,newTarget) {
            var $elem = $(this);
            var $slider = $elem.closest('.form-group').find('.js-regional-adjustment-slider');
            var $visualBar = $elem.closest('.form-group').find('.progress-bar');
            var $card = $slider.closest('div.card-body');
            var $panel = $(this).closest('.js-adjustment-panel');
            var maxTarget = $elem.data('bts-max');
            var oldTarget = $elem.data('bts-min');

            $elem.val(newTarget);

            // update slider
            var sliderPercent = Math.round((newTarget - oldTarget) / (maxTarget - oldTarget) * 100);
            $slider.val(sliderPercent);

            // update visual bar
            $visualBar.css('width', sliderPercent + '%');
            $visualBar.attr('aria-valuenow', sliderPercent);

            // refresh rag status
            refreshRag($panel);

            // update CPL
            var leads = $card.find('input.js-regional-adjustment-leads').val();
            var budget = $card.find('input.js-regional-adjustment-budget').val();

            if(leads > 0) {

                $card.find('input.js-cpl').val((Math.round(budget / leads * 100) / 100).toFixed(2));
            }
        });

        $('.js-regional-adjustment-slider').change(function() {
            var $elem = $(this);
            var $spinner = $elem.closest('.form-group').find('.js-regional-adjustment');
            var maximum = $spinner.data('bts-max');
            var minimum = $spinner.data('bts-min');
            var ratio = ($elem.val() / 100);
            $spinner.val(Math.round((maximum - minimum) * ratio) + minimum);
            $spinner.trigger('change');
        });

        $('.js-regional-adjustment-cancel').click(function() {
            var $cancel = $(this);
            var $panel = $cancel.closest('.collapse');
            $panel.collapse('hide');
        });

       $('.js-adjustment-form-collapse').on('hidden.bs.collapse', function() {
           var $panel = $(this);
           $panel.find('.js-regional-adjustment')
               .each(function(idx,elem) {
                   var $spinner = $(elem);
                   $spinner.val($spinner.data('bts-min'));
                   $spinner.trigger('change');
               });
       });

       $('.js-regional-adjustment-commit').click(function() {

           var $panel = $(this).closest('.js-adjustment-panel');

           var regionName = $panel.data('region-name');
           var entityTypeId = $panel.data('entity-type-id');
           var entityId = $panel.data('entity-id');
           var recentChanges = $panel.data('recent-changes');
           var learningDaysRemaining = $panel.data('learning-days-remaining');

           var $leadsSpinner = $panel.find('.js-regional-adjustment-leads');
           var $budgetSpinner = $panel.find('.js-regional-adjustment-budget');

           var impactOnLeads = $leadsSpinner.val() - $leadsSpinner.data('bts-min');
           var newBudget = $budgetSpinner.val();
           var oldBudget = $budgetSpinner.data('bts-min');

           function budgetFormat(x) {
               return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
           }

           // update the modal

           $('#confirm-region').text(regionName);
           $('#confirm-from').text('£' + budgetFormat(oldBudget));
           $('#confirm-to').text('£' + budgetFormat(newBudget));

           if(learningDaysRemaining > 0) {
               $('#confirm-learning-days-remaining').text(learningDaysRemaining);
               $('#confirm-learning').show();
           } else {
               $('#confirm-learning').hide();
           }

           // populate recent changes
           var $recentChanges = $('#confirmation-modal-recent-changes');
           $recentChanges.html('');

           recentChanges
               .slice(0,4)
               .forEach(function(record) {
                   var recordDate = new Date(record.date);
                   var $row = $('<tr/>');
                   $row.append($('<td>' + recordDate.toLocaleDateString() + '</td>'));
                   $row.append($('<td>' + recordDate.toLocaleTimeString() + '</td>'));
                   $row.append($('<td>£' + budgetFormat(record.from) + '</td>'));
                   $row.append($('<td>£' + budgetFormat(record.to) + '</td>'));

                   $recentChanges.append($row);
                });

           // update the form
           $('#budget_adjustment_entityTypeId').val(entityTypeId);
           $('#budget_adjustment_entityId').val(entityId);
           $('#budget_adjustment_from').val(oldBudget);
           $('#budget_adjustment_to').val(newBudget);
           $('#budget_adjustment_impactOnLeads').val(impactOnLeads);

           $('.js-regional-adjustment-proceed').prop('disabled', null);

           $('#adjustment-confirmation-modal').modal('show');
       });

        $('.js-regional-adjustment-proceed').click(function() {

            $(this).prop('disabled', 'disabled');
            $('form[name=budget_adjustment]').submit();
        });
    };

    $.RegionalBudgetAdjustment = new RegionalBudgetAdjustment();

}(window.jQuery),


    function ($) {
        "use strict";
        $.RegionalBudgetAdjustment.init()
    }(window.jQuery);