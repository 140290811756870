/**
 * Module/App: Admin Section
 */

!function ($) {
    "use strict";

    function init() {
        $('#criteria_params_and_settings_resultsVs').change(function (ev) {
            $('form').submit();
        })
    }

    // The init will run in the document ready
    $(init);

}(window.jQuery);