/**
 * Module/App: Admin Section
 */

!function ($) {
    "use strict";

    function init() {
        $('.campaign-type-id-select').change(function () {
            window.setTimeout(function () {
                $('form').submit();

            }, 205);
        })
    }

    // The init will run in the document ready
    $(init);

}(window.jQuery);