/**
 * Module/App: Admin Section
 */

!function ($) {
    "use strict";

    function init() {
        var buttons = $('#period-selection-btns').find('button');
        var $criteriaParamsAndSettingsPeriod = $('#criteria_params_and_settings_period');

        var startDate = $('.date-range-start');
        var endDate = $('.date-range-end');

        $('.date-range-type').val("CUSTOM");

        var start = new Date(startDate.val());
        var end = new Date(endDate.val());
        var now = new Date();

        buttons.click(function (ev) {

            startDate.val($(this).data('period-start'));
            endDate.val($(this).data('period-end'));
            $criteriaParamsAndSettingsPeriod.val($(this).data('period-selection'));

            $('.loader-overlay').show();

            $('form').submit();
        });

        // disable unavailable options
        buttons.each(function (item) {
            var self = $(this);
            if (self.data('period-selection') === $criteriaParamsAndSettingsPeriod.val() && !self.hasClass('no-highlight')) {
                self.addClass('btn-success');
                self.removeClass('btn-primary');
            } else {
                var selfStart = new Date(self.data('period-start'));
                var selfEnd = new Date(self.data('period-end'));
                var sameStart = start.toString() === selfStart.toString();
                var sameEnd = end.toString() === selfEnd.toString();

                if (sameStart && sameEnd) {
                    self.addClass('btn-success');
                    self.removeClass('btn-primary');
                    $criteriaParamsAndSettingsPeriod.val($(this).data('period-selection'));
                }
            }

            var isReportForm = $('#report_and_dashboard_criteria_dashboardCriteria_period').length > 0;
            if (
                (moment(self.data("period-start")).isAfter(moment())
                    || moment(self.data("period-start")).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD"))
                && isReportForm
            ) {
                $(self).attr('disabled', 'disabled');
                self.removeClass('btn-primary');
                self.addClass('btn-light');
            }
        });

        var isFuture = start > now;
        var secondWeek = $('.weeks-selector').children()[1];
        var currentMonth = new Date($('#currentPeriod').data('period-start')) < now;
        var isSecondWeek = new Date($(secondWeek).data('period-start')) < now;
        var isDay = startDate.val() === endDate.val();

        function disableButton($selector) {
            $selector.attr('disabled', 'disabled');
            $selector.removeClass('btn-primary');
            $selector.addClass('btn-light');
        }

        function enableButton($selector) {
            $selector.removeAttr('disabled');
            $selector.addClass('btn-primary');
            $selector.removeClass('btn-light');
        }


        function disableSelectBtns($selector) {
            $selector.children().each(function () {
                if ($(this).html().trim() !== 'All') {
                    disableButton($(this));
                }
            });
        }

        var $criteriaParamsAndSettingsResultsVs = $('#criteria_params_and_settings_resultsVs');
        if (isFuture) {
            disableSelectBtns($('.device-selection'));
            disableSelectBtns($('#platform-selection'));


            var notRedistAvailable = !currentMonth || !isSecondWeek;

            if (notRedistAvailable) {
                disableButton($('#criteria_params_and_settings_redistribution'));
            }

            // if they have selected a future period then set the results vs to target as yoy will not be available
            var value = $criteriaParamsAndSettingsResultsVs.val();
            if ((value === 'redistribution' && notRedistAvailable)) {
                $criteriaParamsAndSettingsResultsVs.val('target');
            }
        }

        if (isDay) {
            $criteriaParamsAndSettingsResultsVs.val('yoyVsTarget')
        }

        //year buttons
        var $resultsVs = $('.resultVs-option');
        var vsHidden = $('input[name="criteria_params_and_settings[resultsVs]"]');


        if ($criteriaParamsAndSettingsResultsVs.val() === 'yoyVsTarget') {
            $('#criteria_params_and_settings_yoyVsTarget').addClass('btn-success');
            $('#criteria_params_and_settings_yoyVsTarget').removeClass('btn-primary');
        }


        $resultsVs.click(function (ev) {
            var value = $(this).data('option');

            if (vsHidden.val() && vsHidden.val() === value) {

                var todayIsFirstInWeek = now.getDate() === start.getDate() && now.getDay() === start.getDay() && now.getMonth() === now.getMonth();
                vsHidden.val(isFuture || todayIsFirstInWeek ? "target" : "");
            } else {
                vsHidden.val(value);
            }
            $('.loader-overlay').show();
            $('form.js-dashboard').submit();
        });

        if ($criteriaParamsAndSettingsPeriod.val() || $('#period-selection-btns ').find('.btn-success').length) {


            if (vsHidden.val()) {
                var selectedValue = $('#criteria_params_and_settings_' + vsHidden.val());
                selectedValue.addClass('btn-success');
                selectedValue.removeClass('btn-primary');
            }

        } else {
            // disableButton($('#criteria_params_and_settings_target'));
            // disableButton($('#criteria_params_and_settings_redistribution'));
        }

    }

    // The init will run in the document ready
    $(init);

}(window.jQuery);