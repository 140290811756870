! function ($) {
    "use strict";

    var Opportunities = function () {};

    Opportunities.prototype.init = function () {

        $('#opp-select-all').click(function() {
            var checkedStatus = this.checked;
            $('#opportunities-table tbody tr').find('input:checkbox').each(function() {
                $(this).prop('checked', checkedStatus);
            });

            refreshConfirmButton();
        });

        // only enable the confirm button once something is selected
        function refreshConfirmButton()
        {
            $('button.js-submit-opportunities').prop(
                'disabled',
                $('#opportunities-table').find('tbody tr input:checked').length > 0 ? null : 'disabled'
            );
        }

        $('#opportunities-table tbody tr').find('input:checkbox').change(function() {

            refreshConfirmButton();
        });

        // spawn confirmation modal on submit of the opportunities
        $('button.js-submit-opportunities').click(function(e) {

            e.preventDefault();

            var $tableContainer = $('div.js-opportunity-confirmation-table-container');

            $tableContainer.empty();

            var $confirmationTable = $('#opportunities-table').clone();

            // filter anything that we're not going to action
            $confirmationTable.find('tbody tr').each(function(i, el) {

                if($(this).find('input:checked').length === 0) {

                    $(this).remove();
                }

                $(this).find('td').last().remove();

            });

            $confirmationTable.find('thead tr th').last().remove();
            $confirmationTable.find('tfoot').remove();

            $tableContainer.append($confirmationTable);

            // spawn modal
            $('#opportunity-confirmation-modal').modal('show');
        });

        // trigger opportunity form submit with modal confirmation
        $('.js-opportunity-confirmation-proceed').click(function(e) {

            var $form = $('button.js-submit-opportunities').closest('form');

            $form.submit();
        });

        refreshConfirmButton(); //
    };

    $.Opportunities = new Opportunities();


}(window.jQuery),


    function ($) {
        "use strict";
        $.Opportunities.init()
    }(window.jQuery);