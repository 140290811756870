!function ($) {

    var Capacity = function () {
    };

    Capacity.prototype.init = function () {

        var $form = $('form.capacity_planning_js');
        
        $form.on('submit', function () {

            $('.loader-overlay').show();

        });

    };

    $.Capacity = new Capacity();

}(window.jQuery),

    function ($) {
        "use strict";
        $.Capacity.init()
    }(window.jQuery);