/**
 * Module/App: Admin Section
 */

!function ($) {
    "use strict";

    function init() {
        $('.row-expander').click(function (ev) {
            ev.preventDefault();

            var target = undefined;

            var self = $(this);
            if (self.hasClass('mdi-plus-circle')) {
                self.addClass('mdi-minus-circle');
                self.removeClass('mdi-plus-circle');
                target = '.' + $(this).data('target');
                $(target).collapse('show');


            } else if(self.hasClass('mdi-minus-circle')) {
                self.removeClass('mdi-minus-circle');
                self.addClass('mdi-plus-circle');
                target = '.' + $(this).data('target');
                $(target).collapse('hide');

                $(target).find('.row-expander').each(function (item) {
                    var subTarget = '.' + $(this).data('target');

                    $(subTarget).collapse('hide');
                });

            }

        });
    }

    // The init will run in the document ready
    $(init);

}(window.jQuery);