/**
 * Module/App: Admin Section
 */

!function ($) {
    "use strict";

    function init() {
        $('#report_criteria_main_grouping').change(function () {
            $('form').submit();
        })
    }

    // The init will run in the document ready
    $(init);

}(window.jQuery);