!function ($) {

    var Report = function () {
    };

    function findField() {
        var $criteriaMainPlatform = $('#criteria_main_platform');
        var $reportCriteriaMainPlatform = $('#report_criteria_main_platform');

        if ($criteriaMainPlatform.length) {
            return $criteriaMainPlatform;
        } else if ($reportCriteriaMainPlatform.length) {
            return $reportCriteriaMainPlatform
        } else {
            return undefined;
        }
    }

    Report.prototype.init = function () {

        var $form = $('form.js-dashboard');
        var $field = findField();

        if (!$field) {
            return
        }

        function submitForm() {
            $form.submit();
        }

        function setupPlatformSelect() {
            // Set up action for when the user clicks on a platform button
            $('.platform-select').find('button').click(function (e) {
                var value = $(this).data('platform');

                if ($field.val() !== value) {

                    var otherButton = $('.platform-select').find('button');
                    otherButton.removeClass('bg-dark');
                    otherButton.removeClass('border-dark');

                    $(this).addClass('bg-dark');
                    $(this).addClass('border-dark');

                    $field.val(value);
                } else {
                    $(this).removeClass('bg-dark');
                    $(this).removeClass('border-dark');
                    $field.val('');
                }

                submitForm();
            });
        }
        
        setupPlatformSelect();
    };

    $.Report = new Report();

}(window.jQuery),

    function ($) {
        "use strict";
        $.Report.init()
    }(window.jQuery);