/**
 * Module/App: Admin Section
 */
/**
 */
!function ($, moment) {
    "use strict";

    var colours = [
        '#dd1448',
        '#b013ff',
        '#feb522',
        '#00a7ff',
        '#40f2fd',
        '#fde93e',
        '#e488b3',
        '#a2d3aa'
    ];

    var Calendar = function (container, eventListContainer) {
        this.container = container;

        this.eventsListContainer = eventListContainer;

        this.container.addClass('calendar');

        /**
         * @type {moment}
         */
        this.now = moment();

        this.startDate = this.now.clone().startOf('month').startOf('isoweek');

        this.endDate = this.now.clone().endOf('month');

        this.headContainer = undefined;

        this.periodLabel = undefined;

        this.dayLabels = undefined;

        this.days = undefined;

        this.lookupDebounce = undefined;

        this.events = [];
    };

    Calendar.prototype.init = function () {
        this.initMonthSelect();
        this.initDayLabels();
        this.initDays();
        this.update();
    };

    Calendar.prototype.initMonthSelect = function () {
        var self = this;
        this.headContainer = $('<div class="calendar-head">');

        var prevSelect = $('<div class="dripicons-chevron-left">');
        prevSelect.click(function () {
            self.prevPeriod();
        });

        this.periodLabel = $('<div class="calendar-current">');


        var nextSelect = $('<div class="dripicons-chevron-right">');
        nextSelect.click(function (ev) {
            self.nextPeriod();
        });

        this.headContainer.append(prevSelect);
        this.headContainer.append(this.periodLabel);
        this.headContainer.append(nextSelect);
        this.container.append(this.headContainer);
    };

    Calendar.prototype.initDayLabels = function () {
        this.dayLabels = $('<div class="calendar-day-labels">');
        var startOfWeek = moment().startOf('isoweek');
        var endOfWeek = startOfWeek.clone().add(7, 'd');
        while (startOfWeek.isBefore(endOfWeek)) {

            this.dayLabels.append('<div class="day-label ">' + startOfWeek.format('ddd') + '</div>');
            startOfWeek.add(1, 'd');
        }

        this.container.append(this.dayLabels);
    };

    Calendar.prototype.initDays = function () {
        this.days = $('<div class="calendar-months">');
        var start = this.startDate.clone();

        while (start.isBefore(this.endDate)) {
            var week = $('<div class="calendar-week">');
            var endOfWeek = start.clone().add(7, 'd');

            while (start.isBefore(endOfWeek)) {
                var disabled = start.format('M') !== this.now.format('M') ? 'disabled' : '';
                var id = 'day-' + start.format('M-D');
                week.append('<div id="' + id + '" class="calendar-day  ' + disabled + '"><div class="day-nbr">' + start.format('D') + '</div></div>');
                start.add(1, 'd');
            }
            this.days.append(week);
        }

        this.container.append(this.days);

    };

    Calendar.prototype.update = function () {
        this.startDate = this.now.clone().startOf('month').startOf('isoweek');
        this.endDate = this.now.clone().endOf('month');

        this.periodLabel.text(this.now.format('MMMM YYYY'));
        this.days.remove();
        this.initDays();
        this.lookup();
    };

    Calendar.prototype.nextPeriod = function () {
        this.now = this.now.add(1, 'M');
        this.update();
    };

    Calendar.prototype.prevPeriod = function () {
        this.now = this.now.subtract(1, 'M');
        this.update();
    };

    Calendar.prototype.renderEvents = function () {
        var self = this;
        this.events.forEach(function (item, key) {
            var startDate = moment(item.from);
            var endDate = moment(item.to);

            var event = $('<div class="event-list-item"></div>');
            var color = colours[key];
            event.css('background-color', color);

            var eventDate = $('<div class="event-list-item-date-range"></div>');
            var startText = startDate.format("ddd DD MMMM");
            var endText = endDate.format("ddd DD MMMM");
            var dateRangeText = startText === endText ? startText : startText + ' - ' + endText;

            eventDate.text(dateRangeText);
            event.append(eventDate);

            var eventTitle = $('<div class="event-list-item-title"></div>');
            eventTitle.text(item.name);
            event.append(eventTitle);

            var dayStart = startDate.clone();
            while (dayStart.isSameOrBefore(endDate)) {
                var dayId = '#day-' + dayStart.format('M-D');

                var day = $(dayId);
                day.addClass('highlight-day');
                day.append('<div class="day-highlighter" style="background-color: ' + color + '">');

                dayStart.add(1, 'd');
            }

            self.eventsListContainer.append(event);
        });

        this.joinHighlighted();
    };

    Calendar.prototype.joinHighlighted = function () {
        $('.highlight-spacer').remove();
        $('.highlight-day').each(function () {
            var next = $(this).next();
            if (next.hasClass('highlight-day')) {
                var spacer = $('<div>');
                spacer.css('background-color', $(this).find('.day-highlighter').css('background-color'));
                spacer.css('position', 'absolute');
                spacer.css('top', '-9px');
                spacer.css('height', '50px');
                spacer.css('left', '50%');
                spacer.css('z-index', '1');
                spacer.addClass('highlight-spacer');

                var thisPosition = $(this).find('.day-highlighter').offset();
                var otherPosition = next.find('.day-highlighter').offset();
                spacer.css('width', otherPosition.left - thisPosition.left);

                $(this).append(spacer);

                var diff = thisPosition.top - spacer.offset().top;
                spacer.css('top', '-' + (9 - diff) + 'px')
            }
        });
    };

    Calendar.prototype.lookup = function () {
        var self = this;
        window.clearTimeout(this.lookupDebounce);

        $('.event-list-item').remove();
        this.lookupDebounce = window.setTimeout(function () {
            $.get('/events/lookup/' + self.startDate.format('YYYY-MM-DD') + '/' + self.endDate.format("YYYY-MM-DD")).then(function (res) {
                var events = JSON.parse(res);

                if (events.length) {
                    self.events = events;
                } else {
                    self.events = [];
                }
                self.renderEvents();
            });
        }, 200);
    };

    var init = function () {
        var $calendar = $('#calendar');

        if (!$calendar.length) {
            return;
        }

        var $events = $('#events');
        $calendar.Calendar = new Calendar($calendar, $events);
        $calendar.Calendar.init();

        $(window).resize(function () {
            $calendar.Calendar.joinHighlighted();
        });
    };

    // The init will run in the document ready
    $(init);

}(window.jQuery, window.moment);