/**
 * Module/App: Admin Section
 */

!function ($) {
    "use strict";


    var init = function () {
        dataGraph('.accumulative-spend-graph', {
            data: function () {
                return JSON.parse($('#accumulative-data').html());
            },
            title: 'Monthly Cumulative Spend',
            lines: [{
                name: 'spend',
                yAxis: 'spend',
                colour: '#b013ff'
            }],
            XAxis: 'time',
            dateFields: ['time'],
            accumulate: ['spend']
        });

    };

    // The init will run in the document ready
    $(init);

}(window.jQuery);