! function ($) {
    "use strict";

    var ClientSelector = function () {};

    ClientSelector.prototype.init = function () {

        $('.js-client-selector').change(function() {
            var $selector = $(this);
            var $form = $selector.closest('form');

            $.post('/select-client', $form.serialize())
                .done(function() {
                    /* could trigger an event here and reload data dynamically instead of doing a redirect */
                    window.location.href = window.location.href;
                })
            ;
        });
    };

    $.ClientSelector = new ClientSelector();

}(window.jQuery),


    function ($) {
        "use strict";
        $.ClientSelector.init()
    }(window.jQuery);