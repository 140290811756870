/**
 * Module/App: Admin Section
 */

!function ($) {
    "use strict";


    var init = function () {

        var $cross = $('#collapseExample');
        if (!$cross.length) {
            return;
        }

        $('.level_1_Row').on('hide.bs.collapse', function () {
console.log('closing 1');
            $(this).find('.row-expander.mdi-minus-circle').click();
        });


    };

    // The init will run in the document ready
    $(init);

}(window.jQuery);