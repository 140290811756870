/**
 * Module/App: Admin Section
 */

!function ($) {
    "use strict";


    var init = function () {
        var $events = $('#events-history-table');

        var selectedStore = [];

        if (!$events.length) {
            return;
        }

        var downSelector = 'dripicons-chevron-down';
        var upSelector = 'dripicons-chevron-up';

        $(document).on("click", '.' + downSelector, function (ev) {
            $(this).parent().parent().find('.stores').show();
            $(this).removeClass(downSelector);
            $(this).addClass(upSelector);
        });
        $(document).on("click", '.' + upSelector, function (ev) {
            $(this).parent().parent().find('.stores').hide();
            $(this).removeClass(upSelector);
            $(this).addClass(downSelector);
        });


        var storeLookupDebounce;
        var lookupSelect = $('<div>');
        lookupSelect.addClass('lookupSelect');


        function selectStore(item) {

            selectedStore.push(item.name);

            var storeCount = $('.store-hidden').length;

            var $storeRow = $('<div class="store-row">');
            $storeRow.html('<div>' + item.name + '</div><a href="" class="removeStore">remove</a>');

            var input = $('<input class="form-control store-hidden" type="hidden" name="event[stores][' + storeCount + ']" id="event_stores_' + storeCount + '" value="' + item.id + '">');

            $storeRow.append(input);

            $('.eventstores').append($storeRow);
        }

        $(document).on("click", ".removeStore", function (ev) {
            ev.preventDefault();
            $(this).parent().remove();
        });

        function lookUp(value) {

            $.get('/stores/lookup?search=' + value).then(function (res) {
                lookupSelect.children().remove();
                if (res.length) {
                    res.forEach(function (item) {

                        if (selectedStore.indexOf(item.name) !== -1) {
                            return;
                        }


                        var lookupResult = $('<div>');
                        lookupResult.addClass('item-' + item.id);
                        lookupResult.text(item.name);
                        lookupResult.storeData = item;
                        lookupSelect.append(lookupResult);

                        var link = $('<a href="#">Add</a>');
                        link.click(function (ev) {
                            ev.preventDefault();
                            selectStore(item);
                            lookupSelect.remove();
                            $('.storeSelect').val("")
                        });
                        link.css('float', 'right');

                        lookupResult.append(link);
                    });
                } else {
                    var lookupResult = $('<div>');
                    lookupResult.text("No stores found...")
                    lookupSelect.append(lookupResult);
                }

            });
        }

        function setupStoreSelectListeners() {
            $('.storeSelect').keyup(function (ev) {
                var value = $(this).val();
                if (!$(this).find('.lookupSelect').length) {
                    $(this).parent().append(lookupSelect);
                }

                if (!value) {
                    lookupSelect.remove();
                    return;
                }

                window.clearTimeout(storeLookupDebounce);
                storeLookupDebounce = window.setTimeout(function () {
                    lookUp(value);
                }, 250);

            });
        }

        $('#addEvent').click(function (ev) {
            var $eventFormModal = $('#eventFormModal');
            $eventFormModal.modal();
            $eventFormModal.find('input').each(function (item) {
                $(this).val("");
                $(this).removeClass('is-invalid')
            });

            $eventFormModal.find('select').each(function (item) {
                $(this).val("");
                $(this).removeClass('is-invalid')
            });

            $('.store-row').remove();


            $('#saveEventForm').removeAttr('disabled');

            setupStoreSelectListeners();
        });


        $('.edit-event').click(function (ev) {
            ev.preventDefault();
            var form = $('#editEventFormModal');
            form.modal();
            var data = $(this).parent().find('.event-data').text();
            data = JSON.parse(data);

            form.find('input[name="event[eventId]"]').val(data.id);
            form.find('input[name="event[name]"]').val(data.name);
            form.find('select[name="event[eventType]"]').val(data.typeId);
            form.find('input[name="event[startDate]"]').val(data.fromDate);
            form.find('input[name="event[startTime]"]').val(data.fromTime);
            form.find('input[name="event[endDate]"]').val(data.toDate);
            form.find('input[name="event[endTime]"]').val(data.toTime);
            form.find('select[name="event[isNational]"]').val(data.isNational === false ? 1 : 0);
            // form.find('input[name="event[name]"]
            $('.store-row').remove();
            data.locations.forEach(function (item) {
                selectStore(item);
            })
            form.find('select[name="event[impact]"]').val(data.impact);

            setupStoreSelectListeners();
        });

        $('#addNewEventType').click(function (ev) {
            ev.preventDefault();
            var typeCount = $('.eventType').length + 1;
            var row = $('<tr>');
            var input = $('<input type="text" class="form-control eventType" >');
            input.attr('id', 'editable_event_types_types_' + typeCount + '_EventTypeName');
            input.attr('name', 'editable_event_types[types][' + typeCount + '][EventTypeName]');
            var cell1 = $('<td>');
            cell1.append('<input type="hidden" id="editable_event_types_types_' + typeCount + '_EventTypeId" name="editable_event_types[types][' + typeCount + '][EventTypeId]" value="">');
            cell1.append('<input type="hidden" id="editable_event_types_types_' + typeCount + '_eventTypeStatus" name="editable_event_types[types][' + typeCount + '][eventTypeStatus]" value="Active" class="type-status">')
            cell1.append(input);
            cell1.append('<div class="invalid-feedback">Please enter a unique event name</div>');
            var cell2 = $('<td><a href="" class="deleteEventType">Delete</a></td>');
            row.append(cell1);
            row.append('<td></td>')
            row.append(cell2);

            $('#editableEvents tbody').prepend(row);

        });

        $('.editEventType').click(function (ev) {
            ev.preventDefault();

        });

        var eventTypesForm = $('form[name="editable_event_types"]');

        $(document).on("click", '.deleteEventType', function (ev) {
            ev.preventDefault();
            var parent = $(this).parent().parent();
            parent.hide();

            parent.find('.type-status').attr("value", "Inactive");

            $('#saveEventTypes').removeAttr('disabled');
            $(".eventType").removeClass('is-invalid');

            eventsValidation(eventTypesForm)
        });

        $(document).on("click", '#saveEventTypes', function (ev) {
            if (eventsValidation(eventTypesForm)) {
                eventTypesForm.submit();
            } else {
                $('#saveEventTypes').attr('disabled', 'disabled')
            }
        });

        $(document).on("keyup", ".eventType", function () {
            $('#saveEventTypes').removeAttr('disabled');
            $(".eventType").removeClass('is-invalid');
            eventsValidation(eventTypesForm);
        });

        var eventsValidation = function (form) {
            var valid = true;

            var duplicates = [];
            var allValues = [];

            var eventTypes = form.find('.eventType');
            eventTypes.each(function () {
                var value = $(this).val().trim().toLowerCase();

                var active = $(this).parent().find('.type-status').attr('value');
                console.log(active, value);
                if (active === "Active") {

                    if (allValues.indexOf(value) !== -1) {
                        duplicates.push(value);
                    }

                    allValues.push(value);
                }
            });

            var readOnlyTypes = form.parent().find('.read-only-row');

            readOnlyTypes.each(function () {
                var value = $(this).text().trim().toLowerCase();

                if (allValues.indexOf(value) !== -1) {
                    duplicates.push(value);
                }

                allValues.push(value);
            });

            if (duplicates.length) {
                valid = false;

                eventTypes.each(function () {
                    var value = $(this).val().trim().toLowerCase();

                    if (duplicates.indexOf(value) !== -1) {
                        $(this).addClass('is-invalid')
                    }
                });
            }

            return valid;
        };

        $(document).on("click", "#saveEventForm", function (ev) {
            var form = $('#eventFormModal').find('form');
            var storeValidation = storesValidation(form);
            if (eventRequiredValidation(form) && storeValidation) {
                form.submit();
            } else {
                form.parent().parent().find('#saveEventForm').attr('disabled', 'disabled');
                ev.preventDefault()
            }

        });

        $(document).on("click", "#saveEditEventForm", function (ev) {
            var form = $('#editEventFormModal').find('form');
            var storeValidation = storesValidation(form);
            if (eventRequiredValidation(form) && storeValidation) {
                form.submit();
            } else {
                form.parent().parent().find('#saveEventForm').attr('disabled', 'disabled');
                ev.preventDefault();
            }
        });


        $(document).on("keyup", "#eventTypeSearch", function (ev) {
            var value = $(this).val();
            var pattern = new RegExp(value, "gi");

            $('#readOnlyEvents tbody tr').each(function (key, item) {
                if (!$(this).html().match(pattern)) {
                    $(this).hide();
                } else {
                    $(this).show();
                }
            });

            $('#editableEvents tbody tr').each(function (key) {
                if (!$(this).html().match(pattern)) {
                    $(this).hide();
                } else {
                    $(this).show();
                }
            });
        });

        function dateValidation(startDate, endDate, form) {
            var isAfter = moment(startDate.val()).isAfter(endDate.val());
            if (isAfter) {
                startDate.addClass('is-invalid');
                endDate.addClass('is-invalid');
                form.parent().parent().find('#saveEventForm').attr('disabled', 'disabled');
            } else {
                startDate.removeClass('is-invalid');
                endDate.removeClass('is-invalid');
                form.parent().parent().find('#saveEventForm').removeAttr('disabled');
            }
        }

        $(document).on("change", "input[name='event[startDate]']", function (ev) {
            var form = $(this).parent().parent().parent().parent();

            var startDate = $(this);
            var endDate = form.find("input[name='event[endDate]']");

            dateValidation(startDate, endDate, form);
        });

        $(document).on("change", "input[name='event[endDate]']", function (ev) {
            var form = $(this).parent().parent().parent().parent();

            var startDate = form.find("input[name='event[startDate]']");
            var endDate = $(this);

            dateValidation(startDate, endDate, form);
        });


        $(document).on("change", '.event-required', function () {
            $(this).removeClass('is-invalid');
            $('#saveEventForm').removeAttr('disabled');
            $('#saveEditEventForm').removeAttr('disabled');
        });

        $(document).on('keyup', '.storeSelect', function () {
            $('.storeSelect').removeClass('is-invalid');
            $('#saveEventForm').removeAttr('disabled');
            $('#saveEditEventForm').removeAttr('disabled');
        });

        $(document).on('change', 'select[name="event[isNational]"]', function () {
            $('.storeSelect').removeClass('is-invalid');
            $('#saveEventForm').removeAttr('disabled');
            $('#saveEditEventForm').removeAttr('disabled');
        });


        var storesValidation = function (form) {
            var valid = false;
            var national = form.find('select[name="event[isNational]"]');

            var location = national.val();

            if (location === true) {
                // if no value for location it seems to return true. ???
                location = undefined;
            }


            var stores = form.find('.store-row').length;

            if (location === "1" && stores === 0) {
                form.find('.storeSelect').addClass("is-invalid");
                valid = false;
            } else if (location === "1" && stores > 0) {
                form.find('.storeSelect').removeClass("is-invalid");
                valid = true;
            } else if (location === "0") {
                form.find('.storeSelect').removeClass("is-invalid");
                valid = true;
            } else if (stores === 0) {
                form.find('.storeSelect').addClass("is-invalid");
                valid = false;
            }
            return valid;
        };


        var eventRequiredValidation = function (form) {
            var valid = true;
            var required = form.find('.event-required');
            required.each(function () {

                var value = $(this).val();
                if (!value.length) {
                    $(this).addClass('is-invalid');
                    valid = false;
                } else {
                    $(this).removeClass('is-invalid')
                }
            });

            return valid;
        };

    };


    // The init will run in the document ready
    $(init);


}(window.jQuery);