/**
 * Module/App: Admin Section
 */

!function ($) {
    "use strict";

    function init() {
        // If the side nav as a submenu, something prevents default behaviour when you click on the menu icon
        // this will redirect
        $('.side-nav-link').click(function () {
            window.location = $(this).attr('href');
        });
    }

    // The init will run in the document ready
    $(init);

}(window.jQuery);