! function ($) {
    "use strict";

    var DataTable = function () {};

    DataTable.prototype.init = function () {

        $('button.js-expand-table, button.js-collapse-table').click(function() {

            console.log('expand/collapse table clicked');

            var expand = $(this).hasClass('js-expand-table');

            $(this)
                .closest('div.dataTables_wrapper')
                .find('table.dataTable tbody').find('tr.hide, tr.unhide')
                .toggleClass('hide unhide');

            $(this)
                .closest('div.dataTables_wrapper')
                .find('button.js-expand-table, button.js-collapse-table')
                .toggleClass('hide unhide');

            $(this)
                .closest('div.dataTables_wrapper')
                .find('span.js-rowcount-collapsed, span.js-rowcount-all')
                .toggleClass('hide unhide');


        });

    };

    $.DataTable = new DataTable();
}(window.jQuery),


    function ($) {
        "use strict";
        $.DataTable.init()
    }(window.jQuery);