/**
 * Theme: Hyper - Responsive Bootstrap 4 Admin Dashboard
 * Author: Coderthemes
 * Module/App: Profile
 */

! function ($) {
    "use strict";

    var ProbabilityThreshold = function () {};

    ProbabilityThreshold.prototype.init = function () {

        console.log('init p threshold component');

        $('.probability-threshold').each(function(i, el) {

            var $container = $(el);
            var $bar = $container.find('div.threshold-bar');
            var $minPctGroup = $container.find('div.min-pct');
            var $maxPctGroup = $container.find('div.max-pct');

            function updateBar()
            {
                var minVal = parseInt($minPctGroup.find('input').val());
                var maxVal = parseInt($maxPctGroup.find('input').val());

                var $red    = $bar.find('div.progress-bar').eq(0);
                var $amber  = $bar.find('div.progress-bar').eq(1);
                var $green  = $bar.find('div.progress-bar').eq(2);

                $red.text(minVal).css('width', minVal + '%');
                $amber.text(maxVal).css('width', (maxVal - minVal) + '%');
                $green.css('width', (100 - maxVal) + '%');

            }

            function isValidValue(thisVal, twinVal, isMin)
            {

                if(isNaN(thisVal) || thisVal > 100 || thisVal < 0)
                    return false;

                if(isMin)
                    return thisVal <= twinVal;
                else
                    return thisVal >= twinVal;
            }

            function initGroup($group, $inputOther, isMin) {

                var $button = $group.find('button');
                var $input  = $group.find('input');

                // change value on click
                $button.click(function(e) {

                    var change = parseInt($(this).data('change'));
                    var newVal = parseInt($input.val()) + change;

                    if(!isValidValue(newVal, parseInt($inputOther.val()), isMin)) {

                        e.preventDefault();
                        return false;
                    }

                    $input.val(newVal);

                    updateBar();
                });

                // only allow valid changes
                $input.keyup(function(e) {

                    var thisVal = parseInt($input.val());
                    var otherVal = parseInt($inputOther.val());

                    if(!isValidValue(thisVal, otherVal, isMin)) {

                        e.preventDefault();
                        return false;
                    }

                    updateBar();
                })
            };

            // init min group
            initGroup($minPctGroup, $maxPctGroup.find('input'), true);

            // init max group
            initGroup($maxPctGroup, $minPctGroup.find('input'), false);

        });

    };

    $.ProbabilityThreshold = new ProbabilityThreshold();
}(window.jQuery),


    function ($) {
        "use strict";
        $.ProbabilityThreshold.init()
    }(window.jQuery);