/**
 * Module/App: Admin Section
 */

!function ($) {
    "use strict";

    function init() {
        var $criteriaParamsAndSettingsPeriod = $('#criteria_params_and_settings_period');

        $('#apply-custom-date-range').click(function (ev) {
            ev.preventDefault();
            $criteriaParamsAndSettingsPeriod.val('');
            $('form').submit();
        })
    }

    // The init will run in the document ready
    $(init);

}(window.jQuery);