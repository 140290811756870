!function ($) {

    var Dashboard = function () {
    };

    Dashboard.prototype.init = function () {

        var $form = $('form.js-dashboard');
        var $type = $('div.js-dashboard-date-range-type');
        var $dates = $('div.js-dashboard-date-range-dates');
        var valid = false;

        function submitForm() {
            $form.submit();
        }

        $type.find('button').click(function (e) {

            var $select = $type.find('select');
            var val = $(this).data('date-range-type');

            $select.val(val);

            $('.loader-overlay').show();

            submitForm();
        });


        var $start = $('.date-range-start');
        var $end = $('.date-range-end');
        var $applyDateRange = $('#apply-custom-date-range');

        var dateValidation = function (ev) {
            var startDate = moment.utc($start.val());
            var endDate = moment.utc($end.val());
            var now = moment.utc().hours(0).minutes(0).seconds(0).milliseconds(0);
            valid = true;

            // check that start is not before end
            if (startDate.isAfter(endDate)) {
                console.log("1")
                $applyDateRange.attr('disabled', 'disabled');

                $start.addClass('is-invalid');
                $start.next().text("The From date must be before the To date");

                $end.addClass('is-invalid');
                $end.next().text("The To date must be after the from date");
                $('.custom-apply').attr('disabled', 'disabled');
                valid = false;
            } else {
                $applyDateRange.removeAttr('disabled');
                $start.removeClass('is-invalid');
                $end.removeClass('is-invalid');
                $('.custom-apply').removeAttr('disabled');
                valid = true;
            }

            if ($start.hasClass('no-future')) {
                if (startDate.isSameOrAfter(now)) {
                    $start.addClass('is-invalid');
                    $start.next().text("The From date must be before today")
                    $('.custom-apply').attr('disabled', 'disabled');
                    valid = false;
                console.log("2")
                } else {
                    if (valid === true) {
                        $start.removeClass('is-invalid');
                        $('.custom-apply').removeAttr('disabled');
                    }
                    valid = true;
                }
            }

        };

        $start.change(dateValidation);
        $end.change(dateValidation);

        dateValidation();
    };

    $.Dashboard = new Dashboard();

}(window.jQuery),

    function ($) {
        "use strict";
        $.Dashboard.init()
    }(window.jQuery);