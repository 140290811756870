/**
 * Module/App: Admin Section
 */

!function ($) {
    "use strict";


    var init = function () {
        var $from = $('#from-date');
        var $to = $('#to-date');

        function fromBeforeToday() {
            var selected = moment($from.val());
            var now = moment();

            var valid = true;

            if (selected.isAfter(now)) {
                $from.addClass("is-invalid");
                var invalid = $('<div>');
                invalid.addClass("invalid-feedback");
                invalid.text("The from date must be before today");
                $from.after(invalid);
                valid = false;
            }
            return valid;
        }

        $from.change(function (ev) {
            $('.invalid-feedback').remove();

            if (!fromBeforeToday() || !isToBeforeFrom()) {
                return;
            }

            $('#report_criteria_main_dateRange_startDate').val($(this).val());
            $('#report_criteria_main_dateRange_dateRangeType').val("CUSTOM");
            $('form').submit();
        });

        function isToBeforeFrom() {
            var valid = true;
            var from = moment($from.val());
            var to = moment($to.val());

            if (from.isAfter(to)) {
                $to.addClass("is-invalid");
                var invalid = $('<div>');
                invalid.addClass('invalid-feedback');
                invalid.text("The to date must be before the from date");
                $to.after(invalid);
                valid = false;
            }
            return valid;
        }

        $to.change(function (ev) {
            $('.invalid-feedback').remove();

            if (!isToBeforeFrom() || !fromBeforeToday()) {
                return;
            }

            $('#report_criteria_main_dateRange_endDate').val($(this).val());
            $('#report_criteria_main_dateRange_dateRangeType').val("CUSTOM");
            $('form').submit();
        });


        $('#report_criteria_main_yoy').change(function (ev) {
            $('form').submit();
        })


    };

    // The init will run in the document ready
    $(init);

}(window.jQuery);