/**
 * Module/App: Admin Section
 */

!function ($) {
    "use strict";

    var form;
    var userConfirmed = false;

    function getSerialisedForm() {
        var users = [];
        form.find('input').serializeArray().forEach(function (item) {
            var index = item.name.match(/\[(\d*)]/);

            if (!index) {
                return
            }

            index = index[1];
            if (!users[index]) {
                users[index] = {};
            }
            var match = item.name.match(/\[([\D]*)]$/);

            users[index][match[1]] = item.value;
        });
        return users;
    }

    function getNewSuperAdmins(original) {
        // compare the original form to how it is now, find users that have been flagged as super admins
        var modifiedFrom = getSerialisedForm();
        return modifiedFrom.filter(function (item, i) {
            var newUser = !original[i] && item.superAdmin;
            var moddedUser = original[i] && original[i].superAdmin !== item.superAdmin;
            return newUser || moddedUser;
        });
    }


    var submitPasswordConfirmation = function () {
        var $confirmPassword = $('#confirmPassword');
        var pass = $confirmPassword.val();
        if (!pass.length) {
            $confirmPassword.addClass('is-invalid');
            return;
        }
        $.post('/admin/user/confirm', {pass: pass}, function (data) {

            if (data.success) {
                $confirmPassword.removeClass('is-invalid');
                userConfirmed = true;
                form.submit();
            } else {
                // bad password
                $confirmPassword.addClass('is-invalid');
            }


        });
    };


    var init = function () {

        form = $('form[name="user_collection"]');

        if (!form.length) {

            return;
        }

        // Read the form into a json object to be compared when the user submits the form
        var original = getSerialisedForm();

        form.submit(function (ev) {
            var newSuperAdmins = getNewSuperAdmins(original);
            console.log(newSuperAdmins);
            if (newSuperAdmins.length && !userConfirmed) {
                // user has tried to edit super admin privileges, prevent the form submit, get confirmation
                ev.preventDefault();

                $('#jaywingUserConfirmation').modal({});
                var superAdminList = $('#newSuperAdmins');
                superAdminList.html('');
                newSuperAdmins.forEach(function (user) {
                    var listItem = $('<li>');
                    listItem.html(user.firstname + ' ' + user.lastname + ', ' + user.email);
                    superAdminList.append(listItem);

                });
            }
        });

        var $confirmPrivileges = $('#confirmPrivileges');

        $('#confirmPassword').keyup(function (ev) {
            if (ev.which === 13) {
                submitPasswordConfirmation();
            }
        });
        $confirmPrivileges.click(submitPasswordConfirmation)
    };

    // The init will run in the document ready
    $(init);


}(window.jQuery);