/**
 * Module/App: Admin Section
 */

!function ($, JSON) {
    "use strict";


    var init = function () {

        var metaJson = $('#time-series-meta');

        if (!metaJson.length) {
            return;
        }

        var text = metaJson.html();

        if (!text) {
            return;
        }

        var data = JSON.parse(text).filter(function (item) {
            return item.fieldName === 'customMetric1';
        });

        if (!data.length) {
            return;
        }

        var meta = data[0];

        dataGraph('.roc-graph', {
            data: function () {
                return JSON.parse($('#accumulative-data').html());
            },
            title: meta.fieldLabel,
            lines: [{
                name: 'customMetric1',
                yAxis: 'customMetric1',
                colour: '#b013ff',
                label: 'CPA'
            }],
            XAxis: 'time',
            dateFields: ['time'],
            accumulate: []
        });


    };

    // The init will run in the document ready
    $(init);

}(window.jQuery, JSON);